import markerYellow from '../images/map-markers/marker_yellow.png';
import markerGray from '../images/map-markers/marker_grey.png';
import MapUtils from "./map-utils";
import UnobtrusiveFlash from "./flash-messages";

const Merger = {

  initCmpForm: function () {
    $('.rwc-Merger-cmpLink').click(function(ev) {
      let ids = $(this).closest(".rwc-Merger-cmpScope").find('input:checkbox:checked').map(function() { return $(this).prop('value'); });
      if (ids.length < 2) {
        ev.preventDefault();
        UnobtrusiveFlash.showFlashMessage('Wybierz dwie osoby/instytucje');
        return;
      }
      let addr = $(this).prop('href').split('?')[0] + '?';
      $.each(ids, function() { addr += 'ids[]=' + this + '&'; });
      $(this).prop('href', addr);
    });

    $('.rwc-Merger-cleanLink').click(function(e) {
      e.preventDefault();
      $('input:checkbox').prop('checked', false);
    });

    $(".rwc-Merger-copyBtn").click(function(e) {
      e.preventDefault();
      let tr = $(this).closest("tr");
      let src = $(tr).find(".rwc-Merger-srcField").text();
      $(tr).find(".rwc-Merger-dstField").val(src);
    });

    $(".rwc-Merger-wpMove select").change(function (e) {
      let form = $(this).closest("form");
      if (this.value === "") {
        form.find("input[type=submit]").addClass("d-none");
      } else {
        form.find("input[type=submit]").removeClass("d-none");
      }
    }).change();
  },

  initEditForm: function() {
    $('#institution_zipcode').change(function() {
      let v = $("#institution_zipcode").val().trim();
      if (v.match(/^\d{2}-\d{3}$/) !== null) {
        $.get('/merger/institutions/brick', { zipcode: v });
      }
    }).change();
  },

  // *** fix loc form ***

  map: null,
  ll: null,
  oldMarker: null,
  curMarker: null,
  defaultDistance: null,

  getRadius: function() {
    let r = $("#loc_radius").val().trim();
    if (r) {
      return parseInt(r);
    } else {
      return this.defaultDistance;
    }
  },

  updateDistance: function(location) {
    if (this.ll != null) {
      let distance = google.maps.geometry.spherical.computeDistanceBetween(this.ll, location);
      distance = Math.round(distance);
      $(".rwc-Merger-distance").text("Odległość punktu: " + distance + " metrów");
      $(".rwc-Merger-circleCur").removeClass("d-none");
    }
  },

  newPos: function(location) {
    if (this.curMarker != null) {
      this.removeCircle(this.curMarker);
      this.curMarker.setMap(null);
    }
    this.curMarker = new google.maps.Marker({
      position: location,
      map: this.map
    });
  },

  savePos: function(location) {
    $("#institution_lat").val(location.lat());
    $("#institution_lng").val(location.lng());
    Merger.updateDistance(location);
  },

  manualPosChange: function() {
    let lat = $("#institution_lat").val();
    let lng = $("#institution_lng").val();
    let ll = new google.maps.LatLng(lat, lng);
    Merger.newPos(ll);
    Merger.updateDistance(ll);
  },

  toggleCircle: function(marker) {
    if (marker == null) return;

    if (marker.rwCircle != null) {
      this.removeCircle(marker);
      return;
    }

    marker.rwCircle = new google.maps.Circle({
      map: this.map,
      radius: this.getRadius(),
      clickable: false,
      strokeWeight: 1,
      fillColor: '#AA5555'
    });
    marker.rwCircle.bindTo('center', marker, 'position');
  },

  removeCircle: function(marker) {
    if (marker.rwCircle != null) {
      marker.rwCircle.setMap(null);
      marker.rwCircle = null;
    }
  },

  addMarker: function(lat, lng) {
    let ll = new google.maps.LatLng(lat, lng);
    let m = new google.maps.Marker({
      position: ll,
      map: this.map,
      icon: markerYellow
    });
    return m;
  },

  initFixLocForm: function(lat, lng, defaultDistance) {
    if (lat != null) {
      this.ll = new google.maps.LatLng(lat, lng);
    }
    this.defaultDistance = defaultDistance;

    let zoomTo = 14;
    let centerTo = null;
    if (this.ll != null) {
      centerTo = this.ll;
    } else {
      centerTo = new google.maps.LatLng(52, 18.5);
      zoomTo = 6;
    }

    let myOptions = {
      zoom: zoomTo,
      center: centerTo,
      mapTypeId: 'OSM',
      mapTypeControl: false
    };
    this.map = new google.maps.Map(document.getElementsByClassName("rwc-Merger-map")[0], myOptions);
    this.map.mapTypes.set('OSM', MapUtils.osmMapType());
    this.map.setMapTypeId('OSM');

    if (this.ll != null) {
      this.oldMarker = new google.maps.Marker({
        position: this.ll,
        map: this.map,
        icon: markerGray
      });
    }

    $("#targeo_link").change(function(e) {
      Merger.fromTargeoLink();
    });

    $("#institution_lat, #institution_lng").change(function(e) {
      Merger.manualPosChange();
    });

    google.maps.event.addListener(this.map, 'click', function(event) {
      Merger.newPos(event.latLng);
      Merger.savePos(event.latLng);
    });

    $(".rwc-Merger-pointsCount").click(function(e) {
      e.preventDefault();
      $(this).closest("tr").find(".rwc-Merger-point").click();
    });

    $(".rwc-Merger-pointsToggle").click(function(e) {
      e.preventDefault();
      $(this).closest("tr").find(".rwc-Merger-point").toggleClass('d-none');
    });

    $(".rwc-Merger-point").click(function(e) {
      e.preventDefault();
      if ($(this).data("marker") != null) {
        $(this).data("marker").setMap(null);
        $(this).data("marker", null);
        return;
      }
      let lat = parseFloat($(this).data("lat"));
      let lng = parseFloat($(this).data("lng"));
      $(this).data("marker", Merger.addMarker(lat, lng));
    });

    $(".rwc-Merger-circleOld").click(function(e) {
      e.preventDefault();
      Merger.toggleCircle(Merger.oldMarker);
    });

    $(".rwc-Merger-circleCur").click(function(e) {
      e.preventDefault();
      Merger.toggleCircle(Merger.curMarker);
    });
  },

  fromTargeoLink: function() {
    let link = $("#targeo_link").val();
    if (link === "") return;
    let re = /(\d{2}\.\d{3,})/g;
    let arr = link.match(re);
    console.log("match", link, arr);
    if (arr == null || arr.length !== 2) return;
    let lat, lng;
    if (arr[0] < arr[1]) {
      lat = arr[1];
      lng = arr[0];
    } else {
      lat = arr[0];
      lng = arr[1];
    }
    let ll = new google.maps.LatLng(lat, lng);
    Merger.newPos(ll);
    Merger.savePos(ll);
  }

};

export default Merger;
