import MapUtils from "./map-utils";

const pathToNumbers = require.context('images/map-numbers', true);

const PersonSearch = {

  // *** edit form ***

  initEditForm: function() {
    $("#person_surname").change(function() {
      $.get('/people/duplicates',
        { person_id: $(this.form).data("person_id"),
          person_name: $('#person_name').val(),
          person_surname: $('#person_surname').val() })
        .done(
          function(data) {
            $('.rwc-PersonEdit-duplicates').html(data);
          });
    });

    let content = $('.be-content');

    content.on("change", ".main-spec-radio", function() {
      $(".main-spec-radio").prop('checked', false);
      $(".main-spec-radio-false").prop('checked', true);
      $(this).prop('checked', true);
    });

    content.on("click", ".rwc-Fields-add", (ev) => {
      // timeout to make sure that form fields are added
      setTimeout(function() {
        if ($(".rwc-Fields-item:not(.d-none) .main-spec-radio:checked").length === 0) {
          $($(".rwc-Fields-item:not(.d-none) .main-spec-radio")[0]).prop('checked', true);
        }
      }, 1);
    });

  },

  // *** results on matrix table ***

  initMatrix: function() {
    $('.rwc-PST-table').on("click", ".rwc-PST-day", function() {
      let $t = $(this);
      let $m = $(".rwc-PST-table");
      let col = $t.parent().children().index($t);
      let day = $m.find(".rwc-PST-dayHeader").eq(col - 1).data("day");
      let ob_id = $t.closest("tr").data("ob_id");
      Turbolinks.visit('/visits/on_date' +
        '?klass=' + encodeURIComponent($m.data("klass")) +
        '&id=' + encodeURIComponent(ob_id) +
        '&day=' + encodeURIComponent(day));
    });
  },

  // *** results on map ***

  initMap: function(list) {
    let myOptions = {
      zoom: 15,
      center: new google.maps.LatLng(52.397, 20.644),
      mapTypeId: 'OSM',
      mapTypeControl: false
    };

    let findBounds = list.length > 1;
    if (! findBounds) {
      ob = list[0];
      myOptions.center = new google.maps.LatLng(ob.lat, ob.lng);
    }

    PersonSearch.info_win = new google.maps.InfoWindow({ content: "<div class='rwc-PSM-infoWin'>&nbsp;</div>" });

    PersonSearch.g_map = new google.maps.Map(document.getElementsByClassName("rwc-PSM-mapCanvas")[0], myOptions);
    PersonSearch.g_map.mapTypes.set('OSM', MapUtils.osmMapType());
    PersonSearch.g_map.setMapTypeId('OSM');

    let bounds = new google.maps.LatLngBounds();
    let loc, marker, icon, dir, i, ob;
    for (i = 0; i < list.length; i += 1) {
      ob = list[i];
      loc = new google.maps.LatLng(ob.lat, ob.lng);
      dir = PersonSearch.categoryDir(ob.domain_mask);
      icon = pathToNumbers("./" + dir + "/number_" + (ob.count > 100 ? 100 : ob.count) + ".png", true);
      marker = new google.maps.Marker({ position: loc, map: PersonSearch.g_map, icon: icon });
      bounds.extend(loc);

      PersonSearch.attach(marker, ob);
    }
    if (findBounds) {
      PersonSearch.g_map.fitBounds(bounds);
    }

    $('.rwc-PSM-mapCanvas').on("click", ".rwc-PSM-backToList", function(e) {
      e.preventDefault();
      if (PersonSearch.current_marker) {
        google.maps.event.trigger(PersonSearch.current_marker, 'click');
      }
    });
  },

  attach: function(marker, ob) {
    google.maps.event.addListener(marker, 'click', function() {
      PersonSearch.current_marker = marker;
      PersonSearch.info_win.setContent(PersonSearch.spinner());
      PersonSearch.info_win.open(PersonSearch.g_map, marker);
      $.ajax({
        url: "/person_searches/loc_info",
        dataType: "html",
        data: { lat: ob.lat, lng: ob.lng }
      }).done(function(data) {
        PersonSearch.info_win.setContent(data);
      });
    });
  },

  setInfoContent: function(content) {
    PersonSearch.info_win.close();
    PersonSearch.info_win.setContent(content);
    PersonSearch.info_win.open(PersonSearch.g_map, PersonSearch.current_marker);
  },

  spinner: function() {
    return '<div class="d-flex justify-content-center" style="width: 100px; height: 50px;"><div class="spinner-border text-secondary m-2"></div></div>';
  },

  categoryDir: function(mask) {
    switch(mask) {
      case 1: return "m-darkred";
      case 2: return "m-darkblue";
      case 3: return "m-gray";
    }
  },

  current_marker: null,
  g_map: null,
  info_win: null,

  // *** serach filters form ***

  initForm: function(load_addresses) {
    this.initSelect2($("#person_search_specialization_array"));
    this.initSelect2($("#person_search_tags_array"));
    this.updateTargetOptions();
    this.initTarget();

    $("#person_search_visits").change(function() {
      let elem = $(".rwc-PSF-visitDetails");
      if ($(this).val() === "-") {
        elem.collapse('hide');
      } else {
        elem.collapse('show');
      }
    });

    $(".rwc-PSF-resultsView").change(function() {
      let rv = $(".rwc-PSF-resultsView:checked").val();
      let elem = $('.rwc-PSF-calViewOptions');
      if (rv === "visits") {
        elem.collapse('show');
      } else {
        elem.collapse('hide');
      }
    });

    $('.rwc-PSF-apply').on('click', function() {
      $('.rwc-PSF-form').submit();
    });

    $(".rwc-PSF-gotoToday").on('click', function(e) {
      e.preventDefault();
      let opt = PersonSearch.searchOpt();
      Turbolinks.visit(PersonSearch.searchUrl() + $.param({ person_search: opt }));
    });

    $(".rwc-PSF-gotoTomorrow").on('click', function(e) {
      e.preventDefault();
      let opt = PersonSearch.searchOpt();
      let d = new Date();
      d.setDate(d.getDate() + 1);
      opt.visits_begin = PersonSearch.dateToDbStr(d);
      opt.visits_end   = PersonSearch.dateToDbStr(d);
      Turbolinks.visit(PersonSearch.searchUrl() + $.param({ person_search: opt }));
    });

    $(".rwc-PSF-gotoNotVisited").on('click', function(e) {
      e.preventDefault();
      let opt = PersonSearch.searchOpt();
      let startDate = new Date();
      let endDate = new Date();
      startDate.setDate(startDate.getDate() - 22);
      endDate.setDate(endDate.getDate() - 1);
      opt.visits_begin = PersonSearch.dateToDbStr(startDate);
      opt.visits_end   = PersonSearch.dateToDbStr(endDate);
      opt.visits       = "= 0";
      opt.visits_state = "reported";
      Turbolinks.visit(PersonSearch.searchUrl() + $.param({ person_search: opt }));
    });

    $('.rwc-PSF-areasBtn').on('click', function(ev) {
      let url = $(this).data('url');
      let elem = $('.rwc-PSF-areasWrapper');
      if (elem.hasClass('show') || elem.find('.rwc-Utils-treeTable').length > 0) {
        return;
      }
      $.ajax({
        type: "GET",
        url: url,
        dataType: "html"
      }).done(function(data) {
        let areas = $('.rwc-PSF-areasTree');
        areas.html(data);
      });
    });

    if (load_addresses) {
      setTimeout(function() {
        $.get('/person_searches/load_addresses')
          .done(
            function(data) {
              $('.rwc-PSF-addressFields').html(data);
              PersonSearch.initSelect2($("#person_search_city_array"));
              PersonSearch.initSelect2($("#person_search_street_array"));
            });
        }, 10);
    }
  },

  initTarget: function() {
    let t = $("#person_search_target_array");
    if (t.hasClass("select2-hidden-accessible")) {
      t.select2('destroy');
    }
    PersonSearch.initSelect2(t, { minimumResultsForSearch: 30, width: '100%' });
    t.on('change.select2', PersonSearch.changedTarget);
  },

  changedTarget: function() {
    PersonSearch.updateTargetOptions();
    // there is no method to update select2 options so have to destroy and build a new instance
    setTimeout(function() { PersonSearch.initTarget(); }, 0);
  },

  updateTargetOptions: function() {
    let elem = $("#person_search_target_array");
    let single = elem.find("option.single:selected").length > 0;
    let group = elem.find("option.group:selected").length > 0;
    if (single) {
      elem.find("option:not(:selected)").attr("disabled", "disabled");
    } else if (group) {
      elem.find("option.single").attr("disabled", "disabled");
    } else {
      elem.find("option").removeAttr("disabled");
    }
  },

  initSelect2: function(elem, options) {
    elem.select2(options || { width: '100%' });
    elem.on('select2:unselecting', function (evt) {
      $(this).data('unselecting', true);
    });
    elem.on('select2:opening', function (evt) {
      if ($(this).data('unselecting')) {
        $(this).removeData('unselecting');
        evt.preventDefault();
      }
    });
  },

  searchUrl: function() {
    return document.location.href.replace(/\?.*/, "") + "?";
  },

  searchOpt: function() {
    return {
      visits_begin: PersonSearch.dateToDbStr(new Date()),
      visits_end:   PersonSearch.dateToDbStr(new Date()),
      visits:       "> 0",
      visits_state: "all",
      target_array: [""],
      res_view:     "visits",
      cal_view:     "current_month",
      health:       "true",
      pharmacy:     "true" };
  },

  dateToDbStr: function(d) {
    return d.getFullYear() + "-" + (d.getMonth() + 1).toString() + "-" + d.getDate();
  }

};

export default PersonSearch;