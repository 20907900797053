import PerfectScrollbar from '../perfect-scrollbar/js/perfect-scrollbar.esm'

class SideNav {

  constructor() {
    this.config = {
      assetsPath: 'assets',
      imgPath: 'img',
      jsPath: 'js',
      libsPath: 'lib',
      leftSidebarSlideSpeed: 200,
      leftSidebarToggleSpeed: 300,
      enableLeftSidebar: true,
      enableSwipe: true,
      swipeTreshold: 100,
      scrollTop: true,
      openRightSidebarClass: 'open-right-sidebar',
      openLeftSidebarClass: 'open-left-sidebar',
      disabledLeftSidebarClass: 'be-left-sidebar-disabled',
      offCanvasLeftSidebarClass: 'be-offcanvas-menu',
      offCanvasLeftSidebarMobileClass: 'be-offcanvas-menu-sm',
      topHeaderMenuClass: 'be-top-menu',
      closeRsOnClickOutside: true,
      removeLeftSidebarClass: 'be-nosidebar-left',
      collapsibleSidebarClass: 'be-collapsible-sidebar',
      collapsibleSidebarCollapsedClass: 'be-collapsible-sidebar-collapsed',
      openLeftSidebarOnClick: true,
      transitionClass: 'be-animate',
      openSidebarDelay: 400
    };

    this.timers = {};
    this.colors = {};
  }

  bindElems() {
    //this.scrollers = {};
    this.ps_be_scroller_notifications = null;
    this.ps_left_sidebar = null;
    this.ps_be_scroller_left_sidebar = null;
    this.ps_sub_menu_scroller = null;
    this.ps_chat_scroll = null;
    this.ps_todo_scroll = null;
    this.ps_settings_scroll = null;
    this.ps_messages_scroll = null;
    this.ps_aside_scroll = null;

    this.body = $("body");
    this.wrapper = $(".be-wrapper");
    this.topNavbar = $('.be-top-header', this.wrapper);
    this.leftSidebar = $(".be-left-sidebar", this.wrapper);
    this.rightSidebar = $(".be-right-sidebar", this.wrapper);
    this.asideDiv = $(".be-scroller-aside", this.wrapper);
    this.toggleLeftSidebarButton = $('.be-toggle-left-sidebar', this.topNavbar);
    //this.notificationsDiv = $(".be-scroller-notifications", this.topNavbar);
    this.openSidebar = false;

    this.firstAnchor = $(".sidebar-elements > li > a", this.leftSidebar);
    this.anchor = $(".sidebar-elements li a", this.leftSidebar);
    this.lsc = $(".left-sidebar-scroll", this.leftSidebar);
    this.lsToggle = $(".left-sidebar-toggle", this.leftSidebar);
    this.openLeftSidebarOnClick = this.config.openLeftSidebarOnClick ? true : false;

    this.rsScrollbar = $(".be-scroller", this.rightSidebar);
    this.rsChatScrollbar = $(".be-scroller-chat", this.rightSidebar);
    this.rsTodoScrollbar = $(".be-scroller-todo", this.rightSidebar);
    this.rsSettingsScrollbar = $(".be-scroller-settings", this.rightSidebar);

    this.menuType = this.leftSidebar.data('menu-type');
  }

  //Get the template css colors into js vars
  // getColor(c) {
  //   let tmp = $("<div>", { class: c }).appendTo("body");
  //   let color = tmp.css("background-color");
  //   tmp.remove();
  //   return color;
  // }

  // Refresh scroller
  updateScroller(ps_object){
    if (ps_object) {
      ps_object.update();
    }
  }

  // Destroy scroller
  destroyScroller(ps_object){
    if (ps_object) {
      ps_object.destroy();
    }
  }

  // Initialize scroller
  initScroller(domObject) {
    if (domObject.length === 0) {
      return null;
    }
    return new PerfectScrollbar(domObject[0], {
      wheelPropagation: false
    });
  }

  // Collapsible sidebar toggle functionality
  toggleSideBar() {
    let button = $(".be-toggle-left-sidebar");

    button.on("click", () => {
      if( this.wrapper.hasClass( this.config.collapsibleSidebarCollapsedClass ) ) {
        this.wrapper.removeClass( this.config.collapsibleSidebarCollapsedClass );
        $("li.open", this.leftSidebar).removeClass("open");
        $("li.active", this.leftSidebar).parents(".parent").addClass("active open");
        this.leftSidebar.trigger("shown.left-sidebar.collapse", { menuType: this.menuType });
        if (!this.ps_be_scroller_left_sidebar || !this.ps_be_scroller_left_sidebar.nodeName) {
          this.ps_be_scroller_left_sidebar = this.initScroller($(".be-scroller", this.leftSidebar));
        }
        this.destroyScroller(this.ps_be_scroller_left_sidebar);
        // Destroy Perfect Scrollbar collapsed instance
        if (typeof this.ps_sub_menu_scroller !== "undefined") {
          this.destroyScroller(this.ps_sub_menu_scroller);
        }
      } else {
        this.wrapper.addClass( this.config.collapsibleSidebarCollapsedClass );
        $("li.active", this.leftSidebar).parents(".parent").removeClass("open");
        $("li.open", this.leftSidebar).removeClass("open");
        this.leftSidebar.trigger("hidden.left-sidebar.collapse", { menuType: this.menuType });
      }
    });
  }

  // Tooltip sidebar funcionality
  tooltipSidebar() {
    let menu = $(".sidebar-elements > li > a", this.leftSidebar);

    for(let i = 0; i <= menu.length; i++ ){
      let _self = menu[i];
      let title = $("> span", _self).text();

      $(_self).attr({
        'data-toggle': 'tooltip',
        'data-placement': 'right',
        'title': title
      });

      $(_self).tooltip({
        trigger: 'manual'
      });
    }

    menu.on('mouseenter', (e) => {
      if(!$.isSm() && this.wrapper.hasClass(this.config.collapsibleSidebarCollapsedClass)){
        $(e.currentTarget).tooltip('show');
      }
    });

    menu.on('mouseleave', (e) => {
      $(e.currentTarget).tooltip('hide');
    });
  }

  // Collapsed sidebar submenu title
  syncSubMenu( item ){
    let elements;

    if( typeof item !== "undefined" ) {
      elements = item;
    } else {
      elements = $(".sidebar-elements > li", this.leftSidebar);
    }

    $.each( elements, (i, elem) => {
      let title = $(elem).find("> a span").html();
      let ul = $(elem).find("> ul");
      let subEls = $("> li", ul);
      title = $('<li class="title">' + title + '</li>');
      let subContainer = $('<li class="nav-items"><div class="be-scroller"><div class="content"><ul></ul></div></div></li>');

      if( !ul.find("> li.title").length ){
        ul.prepend( title );
        subEls.appendTo( subContainer.find(".content ul") );
        subContainer.appendTo( ul );
      }
    });
  }

  // Return boolean whether the sidebar is collapsed or not
  isCollapsed(){
    return this.wrapper.hasClass( this.config.collapsibleSidebarCollapsedClass );
  }

  // Return true if the collapsible left sidebar is enabled
  isCollapsible(){
    return this.wrapper.hasClass( this.config.collapsibleSidebarClass );
  }

  // Close submenu function
  closeSubMenu( subMenu, event ){
    let target = $( event.currentTarget );
    let li = $( subMenu ).parent();
    let openChildren = $( 'li.open', li );

    let clickOutside = !target.closest( this.leftSidebar ).length;
    let slideSpeed = this.config.leftSidebarSlideSpeed;
    let isFirstLevel = target.parents().eq( 1 ).hasClass( 'sidebar-elements' );

    // If left sidebar is collapsed, is not small device
    // and the trigger element is first level
    // or click outside the left sidebar
    if ( !$.isSm() && this.isCollapsed() && ( isFirstLevel || clickOutside ) ){

      li.removeClass( 'open' );
      subMenu.removeClass( 'visible' );
      openChildren.removeClass( 'open' ).removeAttr( 'style' );

    } else { // If not execute classic slide interaction

      subMenu.slideUp({ duration: slideSpeed, complete: () => {
          li.removeClass( 'open' );
          $(subMenu.get(0)).removeAttr( 'style' );

          // Close opened child submenus
          openChildren.removeClass( 'open' ).removeAttr( 'style' );
          if( this.wrapper.hasClass("be-fixed-sidebar") && !$.isSm()){
            this.updateScroller(this.ps_left_sidebar);
          }
        }});

    }
  }

  // Open submenu function
  openSubMenu( anchor, event ){
    let _el = $( anchor );
    let li = $( _el ).parent();
    let subMenu = $( _el ).next();

    let slideSpeed = this.config.leftSidebarSlideSpeed;
    let isFirstLevel = $( event.currentTarget ).parents().eq( 1 ).hasClass( 'sidebar-elements' );

    // Get the open sub menus
    let openSubMenus = li.siblings( '.open' );

    // If there are open sub menus close them
    if( openSubMenus ) {
      this.closeSubMenu( $( '> ul', openSubMenus ), event );
    }

    // If left sidebar is collapsed, is not small device
    // and the trigger element is first level
    if ( !$.isSm() && this.isCollapsed() && isFirstLevel ){
      li.addClass( 'open' );
      subMenu.addClass( 'visible' );

      //Renew Perfect Scroller instance
      if (typeof this.ps_sub_menu_scroller !== "undefined") {
        this.destroyScroller(this.ps_sub_menu_scroller);
      }
      if (!this.ps_sub_menu_scroller || !this.ps_sub_menu_scroller.nodeName) {
        this.ps_sub_menu_scroller = this.initScroller(li.find('.be-scroller'));
      }

      $(window).resize(() => {
        this.waitForFinalEvent(() => {
          if( !$.isXs() ){
            if (typeof this.ps_sub_menu_scroller !== "undefined") {
              this.updateScroller(this.ps_sub_menu_scroller);
            }
          }
        }, 500, "am_check_phone_classes");
      });

    } else { // If not execute classic slide interaction

      subMenu.slideDown({ duration: slideSpeed, complete: () => {
          li.addClass( 'open' );
          $(subMenu.get(0)).removeAttr( 'style' );
          if(this.wrapper.hasClass("be-fixed-sidebar") && !$.isSm()){
            this.updateScroller(this.ps_left_sidebar);
          }
        }});

    }
  }

  /*Off canvas menu*/
  leftSidebarOffCanvas() {

    /*Open Sidebar with toggle button*/
    $(this.toggleLeftSidebarButton).on("click", (e) => {
      if ( this.openSidebar && this.body.hasClass( this.config.openLeftSidebarClass) ) {
        this.body.removeClass(this.config.openLeftSidebarClass);
        this.sidebarDelay();
      } else {
        this.body.addClass( this.config.openLeftSidebarClass + " " + this.config.transitionClass);
        this.openSidebar = true;
      }
      e.preventDefault();
    });

    /*Close sidebar on click outside*/
    $( document ).on("mousedown touchstart", (e) => {
      if ( !$( e.currentTarget ).closest( this.leftSidebar ).length &&
        !$( e.currentTarget ).closest( $(this.toggleLeftSidebarButton) ).length &&
        this.body.hasClass( this.config.openLeftSidebarClass ) ) {

        this.body.removeClass( this.config.openLeftSidebarClass );
        this.sidebarDelay();
      }
    });
  }

  //Core private functions
  leftSidebarInit() {

    // Execute if collapsible sidebar is enabled
    if ( this.isCollapsible() ){
      /*Create sub menu elements*/
      this.syncSubMenu();
      this.toggleSideBar();
      this.tooltipSidebar();

      if( !this.openLeftSidebarOnClick ){

        /*Open sub-menu on hover*/
        this.firstAnchor.on('mouseover', ( event ) => {
          if( this.isCollapsed() ) {
            this.openSubMenu( event.currentTarget, event );
          }
        });

        /*Open sub-menu on click (fix for touch devices)*/
        this.firstAnchor.on('touchstart',( event ) => {
          let anchor = $( event.currentTarget );
          let li = anchor.parent();
          let subMenu = anchor.next();

          if( this.isCollapsed() && !$.isSm() ) {

            if( li.hasClass('open') ) {
              this.closeSubMenu( subMenu, event );
            } else {
              this.openSubMenu( event.currentTarget, event );
            }

            if( $( event.currentTarget ).next().is( 'ul' ) ) {
              event.preventDefault();
            }
          }
        });

        /*Sub-menu delay on mouse leave*/
        this.firstAnchor.on('mouseleave', ( event ) => {
          let _self = $( event.currentTarget );
          let _li = _self.parent();
          let subMenu = _li.find( '> ul' );

          if( !$.isSm() && this.isCollapsed() ){

            //If mouse is over sub menu attach an additional mouseleave event to submenu
            if ( subMenu.length > 0 ){

              setTimeout(() => {
                if( subMenu.is( ':hover' ) ){

                  subMenu.on( 'mouseleave', () => {
                    setTimeout(() => {
                      if( !_self.is( ':hover' ) ){
                        this.closeSubMenu( subMenu, event );
                        subMenu.off( 'mouseleave' );
                      }
                    }, 300);
                  });

                }else{
                  this.closeSubMenu( subMenu, event );
                }
              }, 300);

            }else{
              _li.removeClass( 'open' );
            }
          }
        });
      }

      /*Close sidebar on click outside*/
      $( document ).on("mousedown touchstart", ( event ) => {
        if ( !$( event.target ).closest( this.leftSidebar ).length && !$.isSm() ) {
          this.closeSubMenu( $( "ul.visible", this.leftSidebar), event );
        }
      });
    }

    /*Open sub-menu functionality*/
    this.anchor.on("click", ( event ) => {
      let $el = $(event.currentTarget), $open;
      let $li = $el.parent();
      let $subMenu = $el.next();
      let isFirstLevel = $el.parents().eq(1).hasClass('sidebar-elements');

      // Get the open menus
      $open = $li.siblings(".open");

      if( $li.hasClass('open') ){
        this.closeSubMenu( $subMenu, event );
      }else{
        this.openSubMenu( event.currentTarget, event );
      }

      //If current element has children stop link action
      if( $el.next().is('ul') ){
        event.preventDefault();
      }
    });

    /*Calculate sidebar tree active & open classes*/
    if ( this.wrapper.hasClass( this.config.collapsibleSidebarCollapsedClass ) ){
      $("li.active", this.leftSidebar).parents(".parent").addClass("active");
    } else {
      $("li.active",this. leftSidebar).parents(".parent").addClass("active open");
    }

    /* Add classes if top menu is present */
    if( this.topNavbar.find('.container-fluid > .navbar-collapse').length && this.leftSidebar.length ) {
      this.wrapper.addClass( this.config.offCanvasLeftSidebarClass ).addClass( this.config.offCanvasLeftSidebarMobileClass );
      this.wrapper.addClass( this.config.topHeaderMenuClass );
      this.toggleLeftSidebarButton = $('<a class="nav-link be-toggle-left-sidebar" href="#"><span class="icon mdi mdi-menu"></span></a>');
      $('.be-navbar-header', this.topNavbar).prepend(this.toggleLeftSidebarButton);
    }

    /*Scrollbar plugin init when left sidebar is fixed*/
    if( this.wrapper.hasClass("be-fixed-sidebar") ){
      if( !$.isSm() || this.wrapper.hasClass( this.config.offCanvasLeftSidebarClass ) ) {
        if (!this.ps_left_sidebar || !this.ps_left_sidebar.nodeName) {
          this.ps_left_sidebar = this.initScroller(this.lsc);
        }
      }

      /*Update scrollbar height on window resize*/
      $(window).resize(() => {
        this.waitForFinalEvent(() => {
          if( $.isSm() && !this.wrapper.hasClass( this.config.offCanvasLeftSidebarClass ) ) {
            this.destroyScroller(this.ps_left_sidebar);
          } else {
            if( this.lsc.hasClass('ps') ) {
              this.updateScroller(this.ps_left_sidebar);
            } else {
              if (!this.ps_left_sidebar || !this.ps_left_sidebar.nodeName) {
                this.ps_left_sidebar = this.initScroller(this.lsc);
              }
            }
          }
        }, 500, "be_update_scroller");
      });
    }

    /*Toggle sidebar on small devices*/
    this.lsToggle.on('click', (event) => {
      let spacer = $(event.currentTarget).next('.left-sidebar-spacer');
      let toggleBtn = $(event.currentTarget);
      toggleBtn.toggleClass('open');
      spacer.slideToggle(this.config.leftSidebarToggleSpeed, () => {
        $(spacer).removeAttr('style').toggleClass('open');
      });
      event.preventDefault();
    });

    // Left sidebar off-canvas
    if ( this.wrapper.hasClass( this.config.offCanvasLeftSidebarClass ) ) {
      this.leftSidebarOffCanvas();
    }
  }

  oSidebar() {
    this.body.addClass( this.config.openRightSidebarClass  + " " + this.config.transitionClass );
    this.openSidebar = true;
  }

  cSidebar() {
    this.body.removeClass( this.config.openRightSidebarClass ).addClass( this.config.transitionClass );
    this.sidebarDelay();
  }

  rightSidebarInit() {

    if( this.rightSidebar.length > 0 ){
      /*Open-Sidebar when click on topbar button*/
      $('.be-toggle-right-sidebar').on("click", (e) => {
        if( this.openSidebar && this.body.hasClass( this.config.openRightSidebarClass ) ){
          this.cSidebar();
        }else if( !this.openSidebar ){
          this.oSidebar();
        }

        e.preventDefault();
      });

      /*Close sidebar on click outside*/
      $( document ).on("mousedown touchstart", ( e ) => {
        if ( !$( e.currentTarget ).closest( this.rightSidebar ).length
          && this.body.hasClass( this.config.openRightSidebarClass ) &&
          (this.config.closeRsOnClickOutside || $.isSm()) ) {

          this.cSidebar();
        }
      });
    }

    if (!this.ps_chat_scroll || !this.ps_chat_scroll.nodeName) {
      this.ps_chat_scroll = this.initScroller(this.rsChatScrollbar);
    }
    if (!this.ps_todo_scroll || !this.ps_todo_scroll.nodeName) {
      this.ps_todo_scroll = this.initScroller(this.rsTodoScrollbar);
    }
    if (!this.ps_settings_scroll || !this.ps_settings_scroll.nodeName) {
      this.ps_settings_scroll = this.initScroller(this.rsSettingsScrollbar);
    }

    /*Update scrollbar height on window resize*/
    $(window).resize(() => {
      this.waitForFinalEvent(() => {
        this.updateScroller(this.ps_chat_scroll);
        this.updateScroller(this.ps_todo_scroll);
        this.updateScroller(this.ps_settings_scroll);
      }, 500, "be_rs_update_scroller");
    });

    /*Update scrollbar when click on a tab*/
    $('a[data-toggle="tab"]', this.rightSidebar).on('shown.bs.tab', (e) => {
      this.updateScroller(this.ps_chat_scroll);
      this.updateScroller(this.ps_todo_scroll);
      this.updateScroller(this.ps_settings_scroll);
    });
  }

  sidebarDelay() {
    this.openSidebar = true;
    setTimeout(() => {
      this.openSidebar = false;
    }, this.config.openSidebarDelay );
  }

  sidebarSwipe() {
    /*Open sidedar on swipe*/
    this.wrapper.swipe( {
      allowPageScroll: "vertical",
      preventDefaultEvents: false,
      fallbackToMouseEvents: false,
      swipeLeft: (e) => {
        if( !this.openSidebar && this.rightSidebar.length > 0 ){
          this.body.addClass( this.config.openRightSidebarClass + " " + this.config.transitionClass );
          this.openSidebar = true;
        }
      },
      threshold: this.config.swipeTreshold
    });
  }

  // scrollerInit(){
  //   if (!this.ps_be_scroller_notifications || !this.ps_be_scroller_notifications.nodeName) {
  //     this.ps_be_scroller_notifications = this.initScroller(this.notificationsDiv);
  //   }
  // }

  // scrollTopButton(){
  //   let offset = 220;
  //   let duration = 500;
  //   let button = $('<div class="be-scroll-top"></div>');
  //   button.appendTo("body");
  //
  //   $(window).on('scroll', (e) => {
  //     if ( $(e.currentTarget).scrollTop() > offset ) {
  //       button.fadeIn(duration);
  //     } else {
  //       button.fadeOut(duration);
  //     }
  //   });
  //
  //   button.on('touchstart mouseup', ( e ) => {
  //     $( 'html, body' ).animate({ scrollTop: 0 }, duration);
  //     e.preventDefault();
  //   });
  // }

  //Add and remove active class on left sidebar
  activeItemLeftSidebar(menu_item){
    let firstAnchor = menu_item;
    let li = $(firstAnchor).parent();
    let menu = $(li).parents('li');

    if( !li.hasClass('active') ){
      $('li.active', this.leftSidebar).removeClass('active');
      $(menu).addClass('active');
      $(li).addClass('active');
    }
  }

  initAsidePS() {
    let pas = this.asideDiv;
    if (!$.isSm()) {
      this.ps_aside_scroll = this.initScroller(this.asideDiv);
    }
    $(window).resize(() => {
      if( $.isSm() && !this.wrapper.hasClass( this.config.offCanvasLeftSidebarClass ) ) {
        this.destroyScroller(this.ps_aside_scroll);
      } else {
        if( pas.hasClass('ps') ) {
          this.updateScroller(this.ps_aside_scroll);
        } else {
          if (!this.ps_aside_scroll || !this.ps_aside_scroll.nodeName) {
            this.ps_aside_scroll = this.initScroller(this.asideDiv);
          }
        }
      }
    });
  }

  //Wait for final event on window resize
  waitForFinalEvent(callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "x1x2x3x4";
    }
    if (this.timers[uniqueId]) {
      clearTimeout (this.timers[uniqueId]);
    }
    this.timers[uniqueId] = setTimeout(callback, ms);
  }

  //Init function
  init() {
    let sb = $(".be-left-sidebar");
    if (sb.hasClass('be-sidebar-initialized')) {
      return;
    }
    sb.addClass('be-sidebar-initialized');

    this.bindElems();

    /*Left Sidebar*/
    if ( this.config.enableLeftSidebar ){
      this.leftSidebarInit();
    } else {
      this.wrapper.addClass(this.config.disabledLeftSidebarClass);
    }

    /*Right Sidebar*/
    if (this.rightSidebar.length) {
      this.rightSidebarInit();
    }

    /*Sidebars Swipe*/
    if( this.config.enableSwipe ){
      this.sidebarSwipe();
    }

    // /*Scroll Top button*/
    // if( this.config.scrollTop ){
    //   this.scrollTopButton();
    // }

    /*Page Aside*/
    if( this.asideDiv.length ) {
      this.initAsidePS();
    }

    /*Scroller plugin init*/
    // if (this.notificationsDiv.length) {
    //   this.scrollerInit();
    // }

    /*Get colors*/
    // takes 10ms so disable if not needed
    // this.colors.primary = this.getColor('clr-primary');
    // this.colors.success = this.getColor('clr-success');
    // this.colors.warning = this.getColor('clr-warning');
    // this.colors.danger  = this.getColor('clr-danger');
    // this.colors.grey    = this.getColor('clr-grey');

    /*Get scrollers*/
    // this.scrollers.be_scroller_notifications = this.ps_be_scroller_notifications;
    // this.scrollers.left_sidebar_scroll = this.ps_left_sidebar;
    // this.scrollers.be_left_sidebar_scroll = this.ps_be_scroller_left_sidebar;
    // this.scrollers.sub_menu_scroll = this.ps_sub_menu_scroller;
    // this.scrollers.chat_scroll = this.ps_chat_scroll;
    // this.scrollers.todo_scroll = this.ps_todo_scroll;
    // this.scrollers.settings_scroll = this.ps_settings_scroll;
    // this.scrollers.messages_scroll = this.ps_messages_scroll;
    // this.scrollers.aside_scroll = this.ps_aside_scroll;
    // this.scrollers.updateScroller = this.updateScroller;
    // this.scrollers.destroyScroller = this.destroyScroller;
    // this.scrollers.initScroller = this.initScroller;

    /*Bind plugins on hidden elements*/
    /*Dropdown shown event*/
    // $('.be-icons-nav .dropdown').on('shown.bs.dropdown', () => {
    //   this.updateScroller(this.ps_be_scroller_notifications);
    // });

    /*Tooltips*/
    $('[data-toggle="tooltip"]').tooltip();

    /*Popover*/
    $('[data-toggle="popover"]').popover();

    /*Bootstrap modal scroll top fix*/
    $('.modal').on('show.bs.modal', () => {
      $("html").addClass('be-modal-open');
    });

    $('.modal').on('hidden.bs.modal', () => {
      $("html").removeClass('be-modal-open');
    });
  }

  static start() {
    let sideNav = new SideNav();
    $(document).on('turbolinks:load', () => {
      sideNav.init()
    });
  }

}

export default SideNav;
