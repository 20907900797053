const MapUtils = {

  osmMapType: function() {
    return new google.maps.ImageMapType({
      getTileUrl: function(coord, zoom) {
        return "//osm-tiles.refwell.com/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
      },
      tileSize: new google.maps.Size(256, 256),
      isPng: true,
      alt: "OpenStreetMap layer",
      name: "OpenStreetMap",
      maxZoom: 19
    });
  }

};

export default MapUtils;
