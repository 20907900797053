// Common app code

let refwell = {
  initAreaTree: function(divId, tableId, selectedAreaId) {
    let tt = $("#" + tableId);
    tt.treetable({ expandable: true, indent: 15 });
    tt.treetable("reveal", selectedAreaId);
    tt.treetable("collapseNode", selectedAreaId);
    tt.find("tr[data-tt-id=" + selectedAreaId + "]").addClass("selected");
    tt.on("click", "td a", function(e) {
      e.preventDefault();
      tt.find("tr").removeClass("selected");
      let tr = $(this).closest("tr");
      tr.addClass("selected").focus();
      $("#" + divId).find('input:hidden').val(tr.data("tt-id"));
    });
  },

  reload: function() {
    Turbolinks.visit(location.toString());
  },

  scrollStore: {},
  scrollSelectors: ['.rwc-PST-wrapper', '.rwc-Cal-wrapper'],

  setupScroll: function() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
  },

  saveScrollTop: function() {
    let key = document.location.href;
    let state = { x: window.pageXOffset, y: window.pageYOffset, elemArr: [] };
    for (let i = 0; i < this.scrollSelectors.length; i++) {
      let selector = this.scrollSelectors[i];
      let elem = $(selector);
      if (elem.length > 0) {
        state.elemArr.push({ selector: selector, top: elem.scrollTop(), left: elem.scrollLeft() });
      }
    }
    this.scrollStore[key] = state;
  },

  restoreScrollTop: function() {
    let key = document.location.href;
    if (this.scrollStore.hasOwnProperty(key)) {
      let state = this.scrollStore[key];
      window.scrollTo(state.x, state.y);
      for (let i = 0; i < state.elemArr.length; i++) {
        let elem = state.elemArr[i];
        if (elem.top !== 0) {
          $(elem.selector).scrollTop(elem.top);
        }
        if (elem.left !== 0) {
          $(elem.selector).scrollLeft(elem.left);
        }
      }
    }
  },

  updatePosition: function() {
    let p = "update_position=yes";
    let s = document.location.href;
    s = s.replace(/#.*/, "");
    s = s.replace(/(\?|&)profile_ua_id=\d+/, "");
    if (s.search(p) === -1) {
      s += ((s.search(/\?/) === -1) ? "?" : "&") + p;
    }
    Turbolinks.visit(s);
  },

  highlight: function(domElem) {
    let elem = $(domElem);
    if (!elem.hasClass('rwc-Utils-highlight')) {
      elem.addClass('rwc-Utils-highlight');
      setTimeout(() => {
        elem.removeClass('rwc-Utils-highlight');
      }, 1001);
    }
  },

  updateTitle: function() {
    let title = $('.rwc-Utils-setTitle').text().trim();
    if (title !== '') {
      // console.log("updateTitle", title);
      $('.rwc-Utils-pageTitle').text(title);
    }
  },

  updateLastViewed: function(content) {
    let lv = $('.rwc-Utils-lastViewed');
    if (!lv.hasClass('show')) return;
    $('.content', lv).html(content);
  },

  init: function() {
    let sb = $(".be-left-sidebar");
    if (sb.hasClass('refwell-initialized')) {
      return;
    }
    sb.addClass('refwell-initialized');
    // console.count("init");

    refwell.updateTitle();

    sb.on('shown.left-sidebar.collapse', (event, param) => {
      $.ajax({
        url: param.menuType === 'admin' ? '/admin/update_preferences' : '/user/update_preferences',
        data: { sidebar_state: 'default' },
        type: "POST",
        dataType: "script" });
    });
    sb.on('hidden.left-sidebar.collapse', (event, param) => {
      $.ajax({
        url: param.menuType === 'admin' ? '/admin/update_preferences' : '/user/update_preferences',
        data: { sidebar_state: 'collapsed' },
        type: "POST",
        dataType: "script" });
    });

    let lv = $('.rwc-Utils-lastViewed');
    lv.on('shown.bs.dropdown', () => {
      $('.content', lv).html('<div class="d-flex justify-content-center"><div class="spinner-border text-secondary m-2"></div></div>');
      $.ajax({
        url: '/user/last_objects',
        type: "GET",
        dataType: "script" });
    });
    lv.on('hidden.bs.dropdown', () => {
      $('.content', lv).html('');
    });

    // podłączanie zdarzeń pod document duplikuje eventy w Turbolinks, trzeba dodać sprawdzenie czy eventy są aktywne
    let content = $('.be-content');
    content.on("click", ".rwc-Fields-remove", (ev) => {
      ev.preventDefault();
      let link = $(ev.currentTarget);
      link.prev("input[type=hidden]").val("1");
      link.closest(".rwc-Fields-item").addClass('d-none');
    });
    content.on("click", ".rwc-Fields-add", (ev) => {
      ev.preventDefault();
      let link = $(ev.currentTarget);
      let div         = link.data("div");
      let association = link.data("association");
      let new_form    = link.data("form");
      let new_id = new Date().getTime();
      let regexp = new RegExp("new_" + association, "g");
      $(div).append(new_form.replace(regexp, new_id));
    });

    $('.rwc-Utils-submitOnChange').on('change', (ev) => {
      $(ev.currentTarget.form).submit();
    });
    $('.rwc-Utils-submitOnChangeDP').datepicker().on('changeDate', (ev) => {
      $(ev.currentTarget).closest('form').submit();
    });

    $('.rwc-Utils-perPage').on('change', function(ev) {
      $.ajax({
        url: '/user/update_results_per_page',
        data: { per_page: $(this).val() },
        type: 'POST',
        dataType: "script" }).
      done(() => {
        let s = document.location.href;
        s = s.replace(/#.*/, '');
        s = s.replace(/page=\d+/, '');
        Turbolinks.visit(s);
      });
    });

    $('.rwc-Utils-turboForm').on('submit', function(ev) {
      Turbolinks.visit(this.action + (this.action.indexOf('?') === -1 ? '?' : '&') + $(this).serialize());
      return false;
    });

    $('.rwc-Cart-add').on('click', function(ev) {
      $(this).tooltip('dispose');
      return true
    });

    $.fn.select2.defaults.set('language', 'pl');
  },

  start: function() {
    refwell.setupScroll();
    $(document).on('turbolinks:load', (ev) => {
      refwell.restoreScrollTop();
      refwell.init();
    });
    $(document).on('turbolinks:before-visit', (ev) => {
      refwell.saveScrollTop();
    });
  }

};

export default refwell;