import MapUtils from "./map-utils";

const pathToNumbers = require.context('images/map-numbers', true);

const InstSearch = {

  // *** results on map ***

  initMap: function(list) {
    let myOptions = {
      zoom: 15,
      center: new google.maps.LatLng(52.397, 20.644),
      mapTypeId: 'OSM',
      mapTypeControl: false
    };

    let findBounds = list.length > 1;
    if (! findBounds) {
      ob = list[0];
      myOptions.center = new google.maps.LatLng(ob.lat, ob.lng);
    }

    InstSearch.info_win = new google.maps.InfoWindow({ content: "<div class='rwc-ISM-infoWin'>&nbsp;</div>" });

    InstSearch.g_map = new google.maps.Map(document.getElementsByClassName("rwc-ISM-mapCanvas")[0], myOptions);
    InstSearch.g_map.mapTypes.set('OSM', MapUtils.osmMapType());
    InstSearch.g_map.setMapTypeId('OSM');

    let bounds = new google.maps.LatLngBounds();
    let loc, marker, icon, dir, i, ob;
    for (i = 0; i < list.length; i += 1) {
      ob = list[i];
      loc = new google.maps.LatLng(ob.lat, ob.lng);
      dir = InstSearch.categoryDir(ob.domain_mask);
      icon = pathToNumbers("./" + dir + "/number_" + (ob.count > 100 ? 100 : ob.count) + ".png", true);
      marker = new google.maps.Marker({ position: loc, map: InstSearch.g_map, icon: icon });
      bounds.extend(loc);

      InstSearch.attach(marker, ob);
    }
    if (findBounds) {
      InstSearch.g_map.fitBounds(bounds);
    }

    $('.rwc-ISM-mapCanvas').on("click", ".rwc-ISM-backToList", function(e) {
      e.preventDefault();
      if (InstSearch.current_marker) {
        google.maps.event.trigger(InstSearch.current_marker, 'click');
      }
    });
  },

  attach: function(marker, ob) {
    google.maps.event.addListener(marker, 'click', function() {
      InstSearch.current_marker = marker;
      InstSearch.info_win.setContent(InstSearch.spinner());
      InstSearch.info_win.open(InstSearch.g_map, marker);
      $.ajax({
        url: "/institution_searches/loc_info",
        dataType: "html",
        data: { lat: ob.lat, lng: ob.lng }
      }).done(function(data) {
        InstSearch.info_win.setContent(data);
      });
    });
  },

  spinner: function() {
    return '<div class="d-flex justify-content-center" style="width: 100px; height: 50px;"><div class="spinner-border text-secondary m-2"></div></div>';
  },

  categoryDir: function(mask) {
    switch(mask) {
      case 1: return "m-darkred";
      case 2: return "m-darkblue";
      case 3: return "m-gray";
    }
  },

  current_marker: null,
  g_map: null,
  info_win: null,

  // *** serach form ***

  initForm: function(load_addresses) {
    this.initSelect2($("#institution_search_tags_array"));
    this.initSelect2($("#institution_search_category_array"));
    this.updateTargetOptions();
    this.initTarget();

    $("#institution_search_visits").change(function() {
      let elem = $(".rwc-ISF-visitDetails");
      if ($(this).val() === "-") {
        elem.collapse('hide');
      } else {
        elem.collapse('show');
      }
    });

    $(".rwc-ISF-resultsView").change(function() {
      let rv = $(".rwc-ISF-resultsView:checked").val();
      let elem = $('.rwc-ISF-calViewOptions');
      if (rv === "visits") {
        elem.collapse('show');
      } else {
        elem.collapse('hide');
      }
    });

    $('.rwc-ISF-apply').on('click', function() {
      $('.rwc-ISF-form').submit();
    });

    $(".rwc-ISF-gotoToday").on('click', function(e) {
      e.preventDefault();
      let opt = InstSearch.searchOpt();
      Turbolinks.visit(InstSearch.searchUrl() + $.param({ institution_search: opt }));
    });

    $(".rwc-ISF-gotoTomorrow").on('click', function(e) {
      e.preventDefault();
      let opt = InstSearch.searchOpt();
      let d = new Date();
      d.setDate(d.getDate() + 1);
      opt.visits_begin = InstSearch.dateToDbStr(d);
      opt.visits_end   = InstSearch.dateToDbStr(d);
      Turbolinks.visit(InstSearch.searchUrl() + $.param({ institution_search: opt }));
    });

    $(".rwc-ISF-gotoNotVisited").on('click', function(e) {
      e.preventDefault();
      let opt = InstSearch.searchOpt();
      let startDate = new Date();
      let endDate = new Date();
      startDate.setDate(startDate.getDate() - 22);
      endDate.setDate(endDate.getDate() - 1);
      opt.visits_begin = InstSearch.dateToDbStr(startDate);
      opt.visits_end   = InstSearch.dateToDbStr(endDate);
      opt.visits       = "= 0";
      opt.visits_state = "reported";
      Turbolinks.visit(InstSearch.searchUrl() + $.param({ institution_search: opt }));
    });

    $('.rwc-ISF-areasBtn').on('click', function(ev) {
      let url = $(this).data('url');
      let elem = $('.rwc-ISF-areasWrapper');
      if (elem.hasClass('show') || elem.find('.rwc-Utils-treeTable').length > 0) {
        return;
      }
      $.ajax({
        type: "GET",
        url: url,
        dataType: "html"
      }).done(function(data) {
        let areas = $('.rwc-ISF-areasTree');
        areas.html(data);
      });
    });

    if (load_addresses) {
      setTimeout(function() {
        $.get('/institution_searches/load_addresses')
          .done(
            function(data) {
              $('.rwc-ISF-addressFields').html(data);
              InstSearch.initSelect2($("#institution_search_city_array"));
              InstSearch.initSelect2($("#institution_search_street_array"));
            });
      }, 10);
    }
  },

  initTarget: function() {
    let t = $("#institution_search_target_array");
    if (t.hasClass("select2-hidden-accessible")) {
      t.select2('destroy');
    }
    InstSearch.initSelect2(t, { minimumResultsForSearch: 30, width: '100%' });
    t.on('change.select2', InstSearch.changedTarget);
  },

  changedTarget: function() {
    InstSearch.updateTargetOptions();
    // there is no method to update select2 options so have to destroy and build a new instance
    setTimeout(function() { InstSearch.initTarget(); }, 0);
  },

  updateTargetOptions: function() {
    let elem = $("#institution_search_target_array");
    let single = elem.find("option.single:selected").length > 0;
    let group = elem.find("option.group:selected").length > 0;
    if (single) {
      elem.find("option:not(:selected)").attr("disabled", "disabled");
    } else if (group) {
      elem.find("option.single").attr("disabled", "disabled");
    } else {
      elem.find("option").removeAttr("disabled");
    }
  },

  initSelect2: function(elem, options) {
    elem.select2(options || { width: '100%' });
    elem.on('select2:unselecting', function (evt) {
      $(this).data('unselecting', true);
    });
    elem.on('select2:opening', function (evt) {
      if ($(this).data('unselecting')) {
        $(this).removeData('unselecting');
        evt.preventDefault();
      }
    });
  },

  searchUrl: function() {
    return document.location.href.replace(/\?.*/, "") + "?";
  },

  searchOpt: function() {
    return {
      visits_begin: InstSearch.dateToDbStr(new Date()),
      visits_end:   InstSearch.dateToDbStr(new Date()),
      visits:       "> 0",
      visits_state: "all",
      target_array: [""],
      tags_array: [""],
      res_view:     "visits",
      cal_view:     "current_month" };
  },

  dateToDbStr: function(d) {
    return d.getFullYear() + "-" + (d.getMonth() + 1).toString() + "-" + d.getDate();
  }

};

export default InstSearch;