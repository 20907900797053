import DatepickerUtils from "./datepicker-utils";

const Calendar = {

  init: function() {
    let sb = $(".be-left-sidebar");
    if (sb.hasClass('rw-calendar-initialized')) {
      return;
    }
    sb.addClass('rw-calendar-initialized');

    $('.rwc-Cal-option').on('click', (ev) => {
      ev.preventDefault();
      let el = $(ev.currentTarget);
      let category = el.data('category');
      let checked = el.data('checked') === 1;
      let data = {};
      data[category] = !checked;
      $.ajax({
        url: '/cal/options',
        data: {user_preferences: data},
        dataType: 'script',
        method: 'POST'
      });
    });

    $('.rwc-Cal-owner').on('click', (ev) => {
      ev.preventDefault();
      let el = $(ev.currentTarget);
      let mode = el.data('cal-mode');
      let ownerId = el.data('cal-owner-id');

      let date = null;
      let dateEl = $('.rwc-Cal-date.active');
      if (dateEl.length > 0) {
        date = dateEl.data('cal-date');
      } else {
        dateEl = $('.rwc-Cal-dateInput');
        if (dateEl.length > 0) {
          date = dateEl.val();
        }
      }
      Turbolinks.visit('/cal/' + mode + '?cal_owner_id=' + ownerId + (date != null ? ('&cur_date=' + date) : ''));
    });

    $('.rwc-Cal-ownerSelect').on('change', (ev) => {
      ev.preventDefault();
      let el = $(ev.currentTarget);
      let mode = el.data('cal-mode');
      let ownerId = el.val();

      let date = null;
      let dateEl = $('.rwc-Cal-date.active');
      if (dateEl.length > 0) {
        date = dateEl.data('cal-date');
      } else {
        dateEl = $('.rwc-Cal-dateInput');
        if (dateEl.length > 0) {
          date = dateEl.val();
        }
      }
      Turbolinks.visit('/cal/' + mode + '?cal_owner_id=' + ownerId + (date != null ? ('&cur_date=' + date) : ''));
    });

    $('.rwc-Cal-date').on('click', (ev) => {
      ev.preventDefault();
      let el = $(ev.currentTarget);
      let date = el.data('cal-date');
      let mode = el.data('cal-mode');

      let ownerId = null;
      let ownerEl = $('.rwc-Cal-owner.active');
      if (ownerEl.length > 0) {
        ownerId = ownerEl.data('cal-owner-id');
      }
      Turbolinks.visit('/cal/' + mode + '?cur_date=' + date + (ownerId != null ? ('&cal_owner_id=' + ownerId) : ''));
    });

    DatepickerUtils.attachOnChange('.rwc-Cal-datePicker', function(date) {
      let ownerId = null;
      let ownerEl = $('.rwc-Cal-owner.active');
      if (ownerEl.length > 0) {
        ownerId = ownerEl.data('cal-owner-id');
      }
      Turbolinks.visit('/cal/day?cur_date=' + date + (ownerId != null ? ('&cal_owner_id=' + ownerId) : ''));
    });
  },

  validDateFormat: function(dateString) {
    // Regular expression to match the date format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // Check if the dateString matches the regex
    if (!regex.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    // Check if the month is between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Check the days in month considering leap year for February
    const daysInMonth = [31, (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Check if the day is valid for the given month
    return day > 0 && day <= daysInMonth[month - 1];
  }

};

export default Calendar;
