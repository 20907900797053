const DatepickerUtils = {

  attachOnChange: function (selector, cb) {
    let isMobile = navigator.userAgent.match(/Android|iPhone|iPad/i);
    if (isMobile) {
      selector = selector + ' input';
      $(selector).on('change', (ev) => {
        let date = $(selector).val();
        cb.call(ev.currentTarget, date);
      });
    } else {
      $(selector).on('changeDate', (ev) => {
        // ev.format method returns date in localtime, ev.date is in UTC which has shifted date
        let date = ev.format();
        cb.call(ev.currentTarget, date);
      });
    }
  }

};

export default DatepickerUtils;