import markerYellow from '../images/map-markers/marker_yellow.png';
import markerGreen from '../images/map-markers/marker_green.png';
import markerGray from '../images/map-markers/marker_grey.png';
import markerWhite from '../images/map-markers/marker_white.png';
import markerRed from '../images/map-markers/marker.png';
import MapUtils from "./map-utils";

const LocSummaries = {
  map: null,
  info_win: null,
  startY: null,
  width: null,
  icons: null,

  attachInfoWin: function(marker, content) {
    google.maps.event.addListener(marker, 'click', function() {
      this.info_win.setContent(content);
      this.info_win.open(this.map, marker);
    }.bind(this));
  },

  init: function() {
    this.icons = {
      'summary':          markerYellow,
      'unlocked-visited': markerGreen,
      'unlocked-missed':  markerGray,
      'locked-missed':    markerWhite,
      'locked-visited':   markerRed
    };

    $(".rwc-LocWorkTime-showPoint").click(function (e) {
      e.preventDefault();
      let marker = $(this).closest(".rwc-LocWorkTime-info").data("marker");
      if (marker !== null) {
        google.maps.event.trigger(marker, 'click');
      }
    });

    let mapNode = document.getElementsByClassName("rwc-LocWorkTime-map")[0];
    if (mapNode == undefined) {
      //console.log("no map node");
      return;
    }

    this.info_win = new google.maps.InfoWindow({ content: "<div class='rwc-LocWorkTime-info'>&nbsp;</div>" });

    let myOptions = {
      // show whole Poland
      zoom: 6,
      center: new google.maps.LatLng(52.0, 19.0),
      mapTypeId: 'OSM',
      mapTypeControl: false
    };

    this.map = new google.maps.Map(mapNode, myOptions);
    this.map.mapTypes.set('OSM', MapUtils.osmMapType());
    this.map.setMapTypeId('OSM');

    let bounds = new google.maps.LatLngBounds();
    let labels = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    $(".rwc-LocWorkTime-point").each(function() {
      let ob = $(this);
      let loc = new google.maps.LatLng(ob.data("lat"), ob.data("lng"));
      let marker = null;

      if (ob.data("order")) {
        let order = parseInt(ob.data("order"));
        let isWork = ob.data("is-work") == 't';
        marker = new google.maps.Marker({
          position: loc,
          zIndex: 100,
          map: LocSummaries.map,
          label: {
            color: isWork ? "#000" : "yellow",
            text: labels[order]
          }
        });
      } else {
        marker = new google.maps.Marker({
          position: loc,
          zIndex: 100,
          map: LocSummaries.map
        });
      }

      if (ob.data("radius") && marker) {
        let circle = new google.maps.Circle({
          map: LocSummaries.map,
          radius: ob.data("radius"),
          clickable: false,
          strokeWeight: 1,
          fillColor: '#AA5555'
        });
        circle.bindTo('center', marker, 'position');
      }

      bounds.extend(loc);
      if (marker) {
        ob.data("marker", marker);
        LocSummaries.attachInfoWin(marker, ob.data("info"));
      }
    });

    $(".rwc-LocWorkTime-inst").each(function() {
      let ob = $(this);
      if (ob.data("lat") === undefined) return; // inst without location
      let category = ob.data("category");
      let loc = new google.maps.LatLng(ob.data("lat"), ob.data("lng"));
      let marker = new google.maps.Marker({position: loc, map: LocSummaries.map, icon: LocSummaries.icons[category]});
      bounds.extend(loc);
      ob.data("marker", marker);
      LocSummaries.attachInfoWin(marker, ob.data("info"));
    });

    if ($(".rwc-LocWorkTime-point").length  > 0) {
      this.map.fitBounds(bounds);
    }

    let routePath = $(".rwc-LocWorkTime-route").data("path");
    if (routePath != null) {
      console.log("draw route");
      let pl = new google.maps.Polyline({
        geodesic: true,
        map: this.map,
        path: google.maps.geometry.encoding.decodePath(routePath),
        strokeColor: '#0000ff',
        strokeOpacity: 0.5,
        strokeWeight: 3
      });
    }

  },

};

export default LocSummaries;