import Issues from "./issues";
import Institutions from "./institutions";
import LocDayReport from "./loc-day-report";
import InstSearch from "./inst-search";
import Visits from "./visits";
import KamVisits from "./kam_visits";
import Unblock from "./unblock";
import RepoBrowser from "./repo-browser";
import LocSummaries from "./loc-summaries";
import Merger from "./merger";
import StartCards from "./start-cards";
import Calendar from "./calendar";
import AdminLoc from "./admin-loc";
import PersonSearch from "./person-search";
import Samples from "./samples";

let RwForms = {
  calendar: Calendar,
  start_cards: StartCards,
  person_search: PersonSearch,
  institutions: Institutions,
  inst_search: InstSearch,
  visits: Visits,
  kam_visits: KamVisits,
  issues: Issues,
  loc_day_report: LocDayReport,
  loc_summaries: LocSummaries,
  unblock: Unblock,
  repo_browser: RepoBrowser,
  merger: Merger,
  admin_loc: AdminLoc,
  samples: Samples
};

export default RwForms;
