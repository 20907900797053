const Samples = {
  initPictureCards: function(){
    $(".rwj-SampleHandoutShowReject").on('click', function(e) {
      e.preventDefault();
      let group = $(this).closest(".list-group");
      group.find(".rwj-SampleHandoutScanActions").addClass("d-none");
      group.find(".rwj-SampleHandoutRejectForm").removeClass("d-none");
    });
  }
};
export default Samples;
