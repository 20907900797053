import UnobtrusiveFlash from "./flash-messages";

const Issues = {

  initForm: function() {
    $(".rwc-Issue-category").change(function() {
      if (!this.checked) { return }
      if (this.value === "duplicate") {
        let type = $("#issue_linkable_type").val();
        $("#issue_duplicate_type").val(type);
        $(".rwc-Issue-duplicateId").removeClass('d-none');
        $(".rwc-Issue-deactivateReason").addClass('d-none');
      } else {
        $("#issue_duplicate_type").val("");
        $(".rwc-Issue-duplicateId").addClass('d-none');
        $(".rwc-Issue-deactivateReason").removeClass('d-none');
      }
    }).change();

    $("#issue_duplicate_id").bind("input", function() {
      window.clearTimeout($(this).data("timeout"));
      $(this).data("timeout", window.setTimeout(function() {
        Issues.checkDuplicate();
      }, 1000));
    });

    $(".rwc-Issue-editForm").submit(function() {
      let val = $(".rwc-Issue-category:checked").val();
      if (val === undefined) {
        UnobtrusiveFlash.showFlashMessage("Wybierz kategorię zgłoszenia. Czy to jest duplikat czy też karta do dezaktywacji.");
        return false;
      }
      if (val === "deactivate") {
        let subcategory = $(".rwc-Issue-subcategory:checked").val();
        if (subcategory === undefined) {
          UnobtrusiveFlash.showFlashMessage("Wybierz powód dezaktywacji");
          return false;
        }
      }
      return true;
    });
  },

  checkDuplicate: function() {
    let dupId = $("#issue_duplicate_id").val();
    if (! /^\d+$/.test(dupId)) {
      UnobtrusiveFlash.showFlashMessage("ID to liczba, nie wprowadzaj innych znaków.");
      return;
    }
    let originalId = $("#issue_linkable_id").val();
    let type = $("#issue_duplicate_type").val();

    $.ajax({
      url: "/issues/duplicate",
      dataType: "script",
      data: { duplicate_id: dupId, original_id: originalId, duplicate_type: type }
    });
  }

};

export default Issues;
