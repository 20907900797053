import UnobtrusiveFlash from "./flash-messages";

const StartCards = {

  initVisits: function () {
    $('.rwc-VisitCard-planBtn').on('click', function(ev) {
      ev.preventDefault();
      UnobtrusiveFlash.showFlashMessage('Kliknij na taki przycisk będąc na karcie osoby u której chcesz zaplanować wizytę.');
    });
  }

};

export default StartCards;