const RepoBrowser = {

  init: function() {
    $(".rwc-RepoBrowser-uploadLink").click(function(e) {
      e.preventDefault();
      $(this).closest(".rwc-RepoBrowser-dirActions").find("form").addClass('d-none');
      $(this).closest(".rwc-RepoBrowser-dirActions").find(".rwc-RepoBrowser-uploadForm").removeClass('d-none');
    });

    $(".rwc-RepoBrowser-addDirLink").click(function(e) {
      e.preventDefault();
      $(this).closest(".rwc-RepoBrowser-dirActions").find("form").addClass('d-none');
      $(this).closest(".rwc-RepoBrowser-dirActions").find(".rwc-RepoBrowser-addDirForm").removeClass('d-none');
    });

    $(".rwc-RepoBrowser-cancel").click(function(e) {
      e.preventDefault();
      $(this).closest("form").addClass('d-none');
    });

    $(".rwc-RepoBrowser-renameLink").click(function(e) {
      e.preventDefault();
      RepoBrowser.openHide(this, ".rwc-RepoBrowser-renameForm");
    });

    $(".rwc-RepoBrowser-moveLink").click(function(e) {
      e.preventDefault();
      RepoBrowser.openHide(this, ".rwc-RepoBrowser-moveForm");
    });

    $(".rwc-RepoBrowser-removeLink").click(function(e) {
      e.preventDefault();
      RepoBrowser.openHide(this, ".rwc-RepoBrowser-removeForm");
    });

  },

  openHide: function(that, formClass) {
    $(that).closest(".rwc-RepoBrowser-files").find("form").addClass('d-none');
    $(that).closest(".rwc-RepoBrowser-actions").find(formClass).removeClass('d-none');
  },

};

export default RepoBrowser;