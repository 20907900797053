const Unblock = {

  initForm: function() {
    let content = $('.be-content');

    content.on("click", ".rwc-Deadline-cancel", function(e) {
      e.preventDefault();
      $('.rwc-Deadline-wrapper').empty();
      $('.rwc-Deadline-newLink').removeClass('d-none');
    });

    content.on("change", "#report_deadline_user_assignment_id", function(e) {
      e.preventDefault();
      $(".rwc-Deadline-klass").empty();
      $(".rwc-Deadline-details").empty();
      let v = $(this).val();
      if (v === "") {
        return;
      }
      $.ajax({
        url: '/report_deadlines/person',
        dataType: "script",
        data: { ua_id: v }
      });
    });

    content.on("change", "#report_deadline_klass", function(e) {
      e.preventDefault();
      $(".rwc-Deadline-details").empty();
      let v = $(this).val();
      if (v === "") {
        return;
      }
      $.ajax({
        url: '/report_deadlines/klass',
        dataType: "script",
        data: { klass: v }
      });
    });

  }

};

export default Unblock;