import MapUtils from "./map-utils";
import markerGray from '../images/map-markers/marker_grey.png';
import UnobtrusiveFlash from "./flash-messages";

const Institutions = {

  // *** Institution main card ***

  initCard: function() {
    $('.rwc-Inst-mapBtn').on('click', function(ev) {
      ev.preventDefault();

      $('.rwc-Inst-mapWrapper').addClass('rwc-Inst-mapWrapper--visible');

      let actions = $('.rwc-Inst-mapActions');
      let btn = $(".rwc-Inst-mapBtn");
      let instLatLng = new google.maps.LatLng(btn.data("lat"), btn.data("lng"));

      let myOptions = {
        zoom: 14,
        center: instLatLng,
        mapTypeId: 'OSM',
        mapTypeControl: false
      };
      let map = new google.maps.Map(document.getElementsByClassName("rwc-Inst-mapWrapper")[0], myOptions);
      map.mapTypes.set('OSM', MapUtils.osmMapType());
      map.setMapTypeId('OSM');

      let marker = new google.maps.Marker({
        position: instLatLng,
        map: map
      });

      btn.addClass('d-none');
      actions.removeClass('d-none');
    });
  },

  // *** Institution edit form ***

  instId: null,
  currentZipcode: "",
  currentCity: "",
  currentAddress: "",

  getZipcode: function() {
    return $.trim($("#institution_zipcode").val());
  },

  getCity: function() {
    return $.trim($("#institution_city").val());
  },

  getAddress: function() {
    return $.trim($("#institution_address").val());
  },

  checkZipcode: function(val) {
    return val.match(/^\s*\d\d\s*-\s*\d\d\d\s*$/) !== null;
  },

  loadBrickInfo: function() {
    let v = this.getZipcode();
    if (this.checkZipcode(v) && this.currentZipcode !== v) {
      this.currentZipcode = v;
      $.get('/institutions/select_brick', { zipcode: v });
    }
  },

  initCategorySelect: function() {
    $("#institution_institution_category_id").change(function() {
      $.ajax({
        url: '/institutions/form_opt_fields',
        data: { institution_id: Institutions.instId, category_id: $(this).val() },
        type: "GET",
        dataType: "script"
      });
    });
  },

  loadDuplicates: function() {
    let address = this.getAddress();
    let city    = this.getCity();
    let zipcode = this.getZipcode();

    if (address.length < 3 || city.length < 3) {
      return;
    }
    if (address === this.currentAddress && city === this.currentCity) {
      return;
    }
    this.currentAddress = address;
    this.currentCity = city;

    $.get('/institutions/duplicates',
      { id:       this.instId,
        address:  address,
        city:     city,
        zipcode:  zipcode })
      .done(function(data) {
        $('.rwc-InstNew-duplicates').html(data);
      });
  },

  initEditForm: function(instId) {
    this.instId = instId;
    this.currentZipcode = this.getZipcode();
    this.currentCity = this.getCity();
    this.currentAddress = this.getAddress();

    this.initCategorySelect();
    setInterval(Institutions.loadBrickInfo.bind(this), 300);
    setInterval(Institutions.loadDuplicates.bind(this), 300);

    $(".rwc-InstNew-form").submit(() => {
      let zipcodeOk = this.checkZipcode(this.getZipcode());
      if (! zipcodeOk) {
        UnobtrusiveFlash.showFlashMessage("Kod pocztowy ma niepoprawny format.");
      }
      return zipcodeOk;
    });
  },

  initDeptForm: function(instId) {
    this.instId = instId;
    this.initCategorySelect();
  },

  // *** Institution location fix ***

  map: null,
  curMarker: null,
  ll: null,

  initLocFix: function(lat, lng) {
    if (lat != null) {
      this.ll = new google.maps.LatLng(lat, lng);
    }

    let zoomTo = 14;
    let centerTo = null;
    if (this.ll != null) {
      centerTo = this.ll;
    } else {
      centerTo = new google.maps.LatLng(52, 18.5);
      zoomTo = 6;
    }

    let myOptions = {
      zoom: zoomTo,
      center: centerTo,
      mapTypeId: 'OSM',
      mapTypeControl: false
    };
    this.map = new google.maps.Map(document.getElementsByClassName("rwc-LocFix-mapWrapper")[0], myOptions);
    this.map.mapTypes.set('OSM', MapUtils.osmMapType());
    this.map.setMapTypeId('OSM');

    if (this.ll != null) {
      let oldPos = new google.maps.Marker({
        position: this.ll,
        map: this.map,
        icon: markerGray
      });
    }

    google.maps.event.addListener(this.map, 'click', function(event) {
      Institutions.newPos(event.latLng);
    });
  },

  newPos: function(location) {
    if (this.curMarker != null) {
      this.curMarker.setMap(null);
    }
    this.curMarker = new google.maps.Marker({
      position: location,
      map: this.map
    });

    $("#institution_lat").val(location.lat());
    $("#institution_lng").val(location.lng());

    $(".rwc-LocFix-lat").text(location.lat());
    $(".rwc-LocFix-lng").text(location.lng());
    if (this.ll != null) {
      let distance = google.maps.geometry.spherical.computeDistanceBetween(this.ll, location);
      distance = Math.round(distance);
      $(".rwc-LocFix-distance").text("Odległość od poprzedniego punktu: " + distance + " metrów");
    }
  }

};

export default Institutions;
