import MapUtils from "./map-utils";
import markerYellow from '../images/map-markers/marker_yellow.png';
import markerGreen from '../images/map-markers/marker_green.png';
import markerGray from '../images/map-markers/marker_grey.png';
import markerWhite from '../images/map-markers/marker_white.png';
import markerRed from '../images/map-markers/marker.png';

const LocDayReport = {
  map: null,
  info_win: null,
  startY: null,
  icons: null,

  attachInfoWin: function(marker, content) {
    google.maps.event.addListener(marker, 'click', function() {
      this.info_win.setContent(content);
      this.info_win.open(this.map, marker);
    }.bind(this));
  },

  init: function() {
    this.icons = {
      'summary':          markerYellow,
      'unlocked-visited': markerGreen,
      'unlocked-missed':  markerGray,
      'locked-missed':    markerWhite,
      'locked-visited':   markerRed
    };

    $("#ua_id, #day").change(function() {
      $(this.form).submit();
    });

    $(".rwc-LocDayReport-dateNav").click(function(e) {
      e.preventDefault();
      $("#day").val($(this).data("date"));
      $(this).closest("form").submit();
    });

    $(".rwc-LocDayReport-show").click(function(e) {
      e.preventDefault();
      let marker = $(this).closest(".rwc-LocDayReport-point").data("marker");
      if (marker !== null) {
        google.maps.event.trigger(marker, 'click');
      }
    });

    this.info_win = new google.maps.InfoWindow({ content: "<div class='loc-short-info'>&nbsp;</div>" });

    let myOptions = {
      // show whole Poland
      zoom: 6,
      center: new google.maps.LatLng(52.0, 19.0),
      mapTypeId: 'OSM',
      mapTypeControl: false
    };

    this.map = new google.maps.Map(document.getElementsByClassName("rwc-LocDayReport-map")[0], myOptions);
    this.map.mapTypes.set('OSM', MapUtils.osmMapType());
    this.map.setMapTypeId('OSM');

    let bounds = new google.maps.LatLngBounds();

    $(".rwc-LocDayReport-point").each(function() {
      let loc, circle, marker;
      let ob = $(this);
      if (ob.data("lat") === undefined) return; // inst without location
      let category = ob.data("category");

      if (category === "extended-location") {
        loc = new google.maps.LatLng(ob.data("lat"), ob.data("lng"));
        circle = new google.maps.Circle({
          map: LocDayReport.map,
          radius: ob.data("radius"),
          center: loc,
          clickable: false,
          strokeWeight: 1,
          fillColor: '#AA5555'
        });
        bounds.extend(loc);

      } else {
        loc = new google.maps.LatLng(ob.data("lat"), ob.data("lng"));
        marker = new google.maps.Marker({position: loc, map: LocDayReport.map, icon: LocDayReport.icons[category]});
        if (category === "summary") {
          circle = new google.maps.Circle({
            map: LocDayReport.map,
            radius: ob.data("radius"),
            clickable: false,
            strokeWeight: 1,
            fillColor: '#AA5555'
          });
          circle.bindTo('center', marker, 'position');
        }
        bounds.extend(loc);
        ob.data("marker", marker);
        LocDayReport.attachInfoWin(marker, ob.data("info"));
      }
    });

    if ($(".rwc-LocDayReport-point").length > 0) {
      this.map.fitBounds(bounds);
    }

  }

};

export default LocDayReport;
