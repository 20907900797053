import MapUtils from "./map-utils";

const AdminLoc = {
  map: null,
  info_win: null,
  startY: null,
  width: null,
  curLr: null,

  attachInfoWin: function(marker, content) {
    google.maps.event.addListener(marker, 'click', function() {
      this.info_win.setContent(content);
      this.info_win.open(this.map, marker);
    }.bind(this));
  },

  init: function() {
    $(".rwc-AdminLocDiag-lsPoint").click(function(e) {
      e.preventDefault();
      var marker = $(this).closest(".rwc-AdminLocDiag-lsPoint").data("marker");
      if (marker !== null) {
        google.maps.event.trigger(marker, 'click');
      }
    });

    var mapNode = document.getElementsByClassName("rwc-AdminLocDiag-map")[0];
    if (mapNode == undefined) {
      console.log("no map node");
      return;
    }

    this.info_win = new google.maps.InfoWindow({ content: "<div class='rwc-AdminLocDiag-info'>&nbsp;</div>" });

    var myOptions = {
      // show whole Poland
      zoom: 6,
      center: new google.maps.LatLng(52.0, 19.0),
      mapTypeId: 'OSM',
      mapTypeControl: false
    };

    this.map = new google.maps.Map(mapNode, myOptions);
    this.map.mapTypes.set('OSM', MapUtils.osmMapType());
    this.map.setMapTypeId('OSM');

    var bounds = new google.maps.LatLngBounds();
    var labels = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    $(".rwc-AdminLocDiag-lsPoint").each(function() {
      var ob = $(this);
      var order = parseInt(ob.data("order"));
      var loc = new google.maps.LatLng(ob.data("lat"), ob.data("lng"));

      var marker = new google.maps.Marker({
        position: loc,
        map: AdminLoc.map,
        label: labels[order]
      });

      var circle = new google.maps.Circle({
        map: AdminLoc.map,
        radius: ob.data("radius"),
        clickable: false,
        strokeWeight: 1,
        fillColor: '#AA5555'
      });
      circle.bindTo('center', marker, 'position');

      bounds.extend(loc);
      ob.data("marker", marker);
      AdminLoc.attachInfoWin(marker, ob.data("info"));

    });

    if ($(".rwc-AdminLocDiag-lsPoint").length > 0) {
      this.map.fitBounds(bounds);
    }

    $(".rwc-AdminLocDiag-lrPoint").click(function(e) {
      var ob = $(this);
      if (ob.data("marker") == null) {
        var label = ob.data("label");
        var id = ob.data("id");
        var lat = ob.data("lat");
        var lng = ob.data("lng");
        var loc = new google.maps.LatLng(lat, lng);

        var marker = new google.maps.Marker({
          position: loc,
          map: AdminLoc.map,
          label: label,
          animation: google.maps.Animation.BOUNCE
        });

        ob.data("marker", marker);
        AdminLoc.attachInfoWin(marker, ob.data("info"));
        ob.addClass("rwc-AdminLocDiag-onMap");

        setTimeout(function() { marker.setAnimation(null); }, 3000);

        if (AdminLoc.curLr != null) {
          var l1 = new google.maps.LatLng(AdminLoc.curLr.lat, AdminLoc.curLr.lng);
          var l2 = new google.maps.LatLng(lat, lng);
          var dist = Math.round(google.maps.geometry.spherical.computeDistanceBetween(l1, l2));

          var $div = $(".rwc-AdminLocDiag-dist");
          $div.html(AdminLoc.curLr.id + '. ' + AdminLoc.curLr.lat + ',' + AdminLoc.curLr.lng + "<br/>" +
            id + '. ' + lat + ',' + lng + "<br/>" +
            "dist " + dist + "m<br/>");
        }
        AdminLoc.curLr = { id: id, lat: lat, lng: lng };

      } else {
        ob.data("marker").setMap(null);
        ob.data("marker", null);
        ob.removeClass("rwc-AdminLocDiag-onMap");
      }
    });

  }

};

export default AdminLoc;
