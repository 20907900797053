// Based on https://github.com/leonid-shevtsov/unobtrusive_flash

let UnobtrusiveFlash = {

  // Delete the cookie regardless of the domain it was set from
  // Partial credit to http://stackoverflow.com/a/2959110/6678
  nukeCookie: function (cookieName) {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let hostParts = window.location.hostname.split('.').reverse();
    let expireHost = hostParts.shift();
    $.each(hostParts, function (i, part) {
      expireHost = part + '.' + expireHost;
      document.cookie = cookieName + '=; path=/;expires=' + yesterday + '; domain=' + expireHost;
    });
    document.cookie = cookieName + '=; path=/;expires=' + yesterday + '; domain=';
  },

  // Extracts flash array stored in cookie and clears the cookie
  extractFlashFromCookies: function () {
    let data = null;
    if (document.cookie && document.cookie !== '') {
      let cookies = document.cookie.split(';');
      let name = 'flash';
      let cookieValue = null;

      for (let i = 0; i < cookies.length; i++) {
        let cookie = jQuery.trim(cookies[i]);
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          // replace fixes problems with Rails escaping. Duh.
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1).replace(/\+/g, '%20'));
          if (cookieValue.length > 0) break; // there might be empty "flash=" cookies
        }
      }

      try {
        data = $.parseJSON(cookieValue);
      } catch (e) {}

      UnobtrusiveFlash.nukeCookie('flash');
    }

    return data;
  },

  showFlashMessage: function(message, options) {
    let class_name = 'clean';
    let sticky = false;
    let time = 3000;

    if (options === undefined) {
      options = { type: 'warning' };
    }

    switch (options.type) {
      case 'notice':
        break;

      case 'success':
        class_name = 'color success';
        break;

      case 'alert':
        class_name = 'color warning';
        time = 6000;
        break;

      case 'error':
        class_name = 'color danger';
        time = 6000;
        break;

      case 'warning':
        class_name = 'color warning';
        time = 6000;
        break;
    }

    $.gritter.add({
      title: null,
      text: message,
      class_name: class_name,
      time: time,
      sticky: sticky
    });
  },

  showFlash: function (flashMessages) {
    if (flashMessages !== null) {
      $.each(flashMessages, function (_, flashMessage) {
        UnobtrusiveFlash.showFlashMessage(flashMessage[1], { type: flashMessage[0] });
      });
    }
  },

  // Reads flash messages from cookies and fires corresponding events
  showFlashFromCookies: function () {
    UnobtrusiveFlash.showFlash(UnobtrusiveFlash.extractFlashFromCookies());
  },

  // We want to remove cookies from the flash as soon as possible, but we only want to show then after all the scripts have loaded,
  // including any flash handlers
  start: function () {
    let pageCookies = UnobtrusiveFlash.extractFlashFromCookies();

    $(window).on('load', function () {
      UnobtrusiveFlash.showFlash(pageCookies);
    });

    $(document).on('turbolinks:load', UnobtrusiveFlash.showFlashFromCookies);
    $(document).on('ajax:complete', UnobtrusiveFlash.showFlashFromCookies);
    $(document).ajaxComplete(function (event, request, options) {
      UnobtrusiveFlash.showFlashFromCookies();
    });
  }
};

export default UnobtrusiveFlash;
