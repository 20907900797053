import UnobtrusiveFlash from "./flash-messages";
import DatepickerUtils from "./datepicker-utils";

const KamVisits = {
  initForm: function () {
    $(".rwc-KamVisit-form").submit(function () {
      KamVisits.setDestroyFlag(".rwc-KamVisit-stockProducts");
      KamVisits.setDestroyFlag(".rwc-KamVisit-soldProducts");
      if (!KamVisits.checkPictures()) {
        UnobtrusiveFlash.showFlashMessage("Wybierz zdjęcie lub usuń pole dodawania nowego zdjęcia.");
        return false;
      }
      return true;
    });
  },

  setDestroyFlag: function (klass) {
    $(klass + " .rwc-KamVisit-amount").each(function (index, el) {
      if (el.value === "" || el.value === 0) {
        let vp_id = $(el).data("visit_product_id");
        $(klass + " .rwc-KamVisit-destroy-" + vp_id).val(true);
      }
    });
  },

  checkPictures: function () {
    let res = true;
    $(".rwc-Fields-item:not(.d-none) input[type=file]").each(function (index, el) {
      if ($(el).val() === "") {
        res = false;
        return false;
      }
    });
    return res;
  }
};

export default KamVisits;