import UnobtrusiveFlash from "./flash-messages";
import DatepickerUtils from "./datepicker-utils";
import Calendar from "./calendar";

const Visits = {

  initNewForm: function(visitId) {
    DatepickerUtils.attachOnChange(".rwc-VisitNew-datePicker", function() {
        let newVal = $("#visit_visit_on").val();
        if (!Calendar.validDateFormat(newVal)) {
          return;
        }
        $.ajax({
          type: 'POST',
          url: '/planned_visits/date_changed',
          data: $(this).closest('form').serialize() + ((visitId === 'new') ? '' : ('&id=' + visitId)),
          dataType: 'script'
        });
      }
    );

    $("#visit_spec_time").change(function() {
      if ($(this).is(":checked")) {
        $('.rwc-VisitNew-beginEnd').removeClass('d-none');
      } else {
        $('.rwc-VisitNew-beginEnd').addClass('d-none');
      }
    }).change();
  },

  initReportForm: function(minAttendeeCount) {
    $(".rwc-VisitReported-form").submit(function() {

      let list1 = [".rwc-VisitTopics-promoted", ".rwc-VisitTopics-present"];
      for (let i = 0; i < list1.length; i++) {
        let klass = list1[i];
        if (!Visits.checkDuplicates(klass)) {
          let mes = $(klass + " .rwc-VisitTopics-title").text() + "<br/>nie mogą powtarzać się";
          UnobtrusiveFlash.showFlashMessage(mes);
          return false;
        }
        Visits.setDestroyFlag(klass);
      }

      let list2 = [".rwc-VisitTopics-visible", ".rwc-VisitTopics-sample"];
      for (let i = 0; i < list2.length; i++) {
        let klass = list2[i];
        Visits.setDestroyFlag2(klass);
      }

      if (!Visits.checkPictures()) {
        UnobtrusiveFlash.showFlashMessage("Wybierz zdjęcie lub usuń pole dodawania nowego zdjęcia.");
        return false;
      }

      if (minAttendeeCount > 0 && $(".rwc-VisitReported-attendees").length > 0) {
        if ($(".rwc-VisitReported-attendees option:selected").length < minAttendeeCount) {
          let mes = "Wybierz co najmniej " + minAttendeeCount + " uczestników prezentacji.";
          UnobtrusiveFlash.showFlashMessage(mes);
          return false;
        }
      }

      return true;
    });

    $(".rwc-VisitReported-saveAndSamples").click(function() {
      $("#next_action").val("goto-samples");
    });
  },

  setDestroyFlag: function(klass) {
    $(klass + " .rwc-VisitTopics-topic").each(function(index, el) {
      if (el.value === "") {
        let pos = $(el).data("position");
        $(klass + " .rwc-VisitTopics-position-" + pos).val(true);
      }
    });
  },

  setDestroyFlag2: function(klass) {
    $(klass + " .rwc-VisitTopics-checkBox input[type=checkbox]").each(function(index, el) {
      if (!$(el).is(":checked")) {
        let pos = $(el).data("position");
        $(klass + " .rwc-VisitTopics-position-" + pos).val(true);
      }
    });
  },

  checkDuplicates: function(klass) {
    let res = true;
    let v = $(klass + " .rwc-VisitTopics-topic").map(function() {
      return $(this).val()
    }).toArray();
    v = v.filter(function(el) {
      return el !== "";
    });
    v.forEach(function(el, i) {
      if (v.indexOf(el, i + 1) !== -1) {
        res = false;
        return false;
      }
    });
    return res;
  },

  checkPictures: function() {
    let res = true;
    $(".rwc-Fields-item:not(.d-none) input[type=file]").each(function(index, el) {
      if ($(el).val() === "") {
        res = false;
        return false;
      }
    });
    return res;
  },

  initVisitCopier: function() {
    $(".rwc-VisitCopier-remove").click(function(e) {
      e.preventDefault();
      $(this).closest(".rwc-VisitCopier-old").remove();
    });

    DatepickerUtils.attachOnChange(".rwc-VisitCopier-date", function() {
      $.ajax({
        type: "POST",
        url: "/visit_plan/day",
        data: $(this).closest('form').serialize(),
        dataType: "script"
      });
    });
  }

};

export default Visits;